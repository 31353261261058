'use client'

import {useBugsnagErrorBoundary} from '~/utils/Bugsnag/useBugsnagErrorBoundary'
import {ErrorBoundaryFallback} from './_components/ErrorBoundary'

export default function Error({error}: {error: Error}) {
  useBugsnagErrorBoundary({error})

  return <ErrorBoundaryFallback />
}
